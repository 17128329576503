<template>
  <section class="documents">
    <v-form
      ref="form"
      :disabled="isLoading"
      class="documents__form"
      @submit.prevent="submit"
    >
      <v-row class="align-center justify-center">
        <v-col cols="6">
          <v-text-field
            v-model="search"
            class="documents__field"
            label="Поиск документов и актов по номеру накладной"
          />
        </v-col>
        <v-col cols="2">
          <v-btn class="mr-3 documents__btn" type="submit" color="primary">
            Поиск
          </v-btn>
          <v-btn class="documents__btn" @click="reset"> Сброс </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-row class="align-center justify-center">
      <v-col cols="8">
        <v-data-table
          v-if="showTable"
          :loading="isLoading"
          class="documents__table"
          :headers="headers"
          :items="items"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              :disabled="isLoading"
              color="primary"
              @click="item.actions(item)"
            >
              Скачать
            </v-btn>
          </template>
          <template v-slot:item.date="{ item }">
            {{ dateToLocal(item.date) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import API from '@/api/api';
import formatDate from '../helpers/formatDate';

export default {
  data() {
    return {
      headers: [
        {
          text: 'Номер документа',
          value: 'number',
          sortable: true,
        },
        {
          text: 'Тип документа',
          value: 'type',
          sortable: true,
        },
        {
          text: 'Дата',
          value: 'date',
          sortable: true,
        },
        {
          text: 'Действия',
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
      items: [],
      search: '',
      isValid: true,
      showTable: false,
      isLoading: false,
      currentInvoice: [],
      currentBills: [],
      validation: {
        orderNumber: [(v) => /\d{10}/g.test(v) || ''],
      },
    };
  },
  async created() {
    try {
      await this.$store.dispatch('loadOrders');
    } catch (error) {
      this.showAlert(error);
    }
  },

  methods: {
    dateToLocal(date) {
      const localDate = new Date(date);
      return localDate.toLocaleString();
    },
    async submit() {
      this.$refs.form.validate();
      if (!this.isValid) {
        return;
      }
      const order = this.$store.getters.currentOrderList.find(
        (order) => order.number === this.search
      );
      try {
        if (!order) {
          throw new Error('Введите существующий номер накладной');
        }
        this.loading = true;
        let { bills, invoices } = await this.$store.dispatch(
          'getOrder',
          order.id
        );
        this.currentBills = bills;
        this.currentBills = this.currentBills.map((bill) => {
          bill.actions = this.getBill;
          return bill;
        });
        this.currentInvoice = invoices;
        this.currentInvoice = this.currentInvoice.map((invoice) => {
          invoice.actions = this.getAct;
          return invoice;
        });
        this.items = this.currentBills.concat(this.currentInvoice);
        this.showTable = true;
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    },
    reset() {
      this.$refs.form.reset();
      this.search = '';
      this.showTable = false;
    },
    async getBill(item) {
      this.isLoading = true;
      try {
        const result = await API.getBlob(`/bills/${item.ID}`);
        const url = URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        const actsName =
          'Bill_№' +
          item.number +
          formatDate(new Date(item.date), '_yyyy_mm_dd') +
          '.pdf';
        link.download = actsName;
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAct(item) {
      try {
        const result = await API.getBlob(`invoices/${item.ID}`);
        const url = URL.createObjectURL(result);
        const link = document.createElement('a');
        link.href = url;
        const actsName =
          'Act_№' +
          item.number +
          formatDate(new Date(item.date), '_yyyy_mm_dd') +
          '.pdf';
        link.download = actsName;
        link.click();
        URL.revokeObjectURL(url);
        link.remove();
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.documents {
  &__form {
  }
  &__table {
  }
}
</style>
